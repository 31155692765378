.container__custom__toast {
    position: fixed;
    padding: 0.7rem 1.2rem;
    border-radius: 10px;
    min-width: 12rem;
    height: 3.7rem;
    z-index: 500;
    right: 10px;
    background-color: rgba(144, 203, 244, 0.6);
    transition: all ease-in-out 0.3s;
}

.open {
    transform: translateY(-30px);
}

.close__button__toast {
    position: absolute;
    right: 1px;
    top: 1px;
}

.item-enter {
    transform: translateX(200px);
    opacity: 0;
  }
  .item-enter-active {
    transform: translateX(0px);
    opacity: 1;
    transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .item-exit {
    opacity: 1;
    transform: translateX(0px);
  }
  .item-exit-active {
    opacity: 0;
    transform: translateX(200px);
    transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }